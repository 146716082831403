import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Img from "gatsby-image";
import Fade from 'react-reveal/Fade'

function Video(props) {
    return (
        <div className="col-md col-12">
            <div className="ratio ratio-16x9">
                <iframe src={props.url} allowfullscreen title={props.title}></iframe>
            </div>
            <p aria-hidden="true" className="mt-3 smaller font-weight-bold">{props.title}</p>
        </div>
    )
}

const Teaching = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
    <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
    <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
        <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span></a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span> <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="container pt-5 bio">
    <Fade>
    <div className="text-center"><h2 className="mb-4">
        <span className="gradient">Teaching Philosophy</span>
        <br/>
        <span className="gradient">教学理念</span>
        </h2></div>
        <div className="row">
            <div className="col-12 col-lg adj">
            <Img fluid={data.photo.data.file.localFiles[0].childImageSharp.fluid} alt={data.photo.data.alt} className="mb-5" />
            </div>
            <div className="col-12 col-lg">
            <div dangerouslySetInnerHTML={{
            __html: data.text.data.text.childMarkdownRemark.html,
            }} className="bio-text" />
            </div>
        </div>
        <br/><br/><br/>
    <h3><span className="gradient">Selected Achievements 教学获奖</span></h3>
    <ul className="no-bullets">
        {data.lishan.data.competitions.map(competition => (
            <li key={competition.id} className="mb-3">{competition.data.Title} | <strong>{competition.data.month} {competition.data.year}</strong></li>
        ))}
    </ul>
    <div className="spacing-lg"></div>
    </Fade>
    {/* <h2 className="my-5"><span className="gradient">Student Profile</span></h2>
        <div className="row">
        {data.students.nodes.map(node => (
            <div className="col-12">
                <div className="row">
                    {node.data.title === null && (
                        <div className="col-md col-12">
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="mb-5 student-photo" />   
                        </div>
                    )}
                    {node.data.title !== null && (
                        <div className="col-md col-12">
                        <Video
                        url={node.data.url}
                        title={node.data.title}
                        />
                        </div>
                    )}
                    <div className="col-md col-12 vertical-center">
                    <div dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="bio-text" />
                    </div>
                </div>
            </div>
        ))}
        </div> */}
        <Fade>
        <div className="text-center"><h2 className="my-5">
            <span className="gradient">Students Award Wall</span>
            <br/>
            <span className="gradient">学生获奖</span>
            </h2></div>
        <div className="row">
            <div className="col-3">
                <p className="h5" style={{textAlign:'center'}}><span className="gradient">Name</span><br/><span className="gradient">姓名</span></p>
            </div>
            <div className="col-3" style={{wordWrap: 'break-word'}}>
                <p className="h5" style={{textAlign:'center'}} ><span className="gradient" style={{display: 'block'}}>Teaching&nbsp;Period</span><span className="gradient">学习时长</span></p>
            </div>
            <div className="col-6 text-center">
                <p className="h5"><span className="gradient">Achievements</span><br/><span className="gradient">获奖</span></p>
            </div>
        </div>
        {data.achievements.nodes.map(node => (
        <div>
        <div className="row mt-5">
        {/* {node.data.notes === null && (
            <div className="col-3">
                <h3>{node.data.name}</h3>
                <p>Age {node.data.age}</p>
            </div>
        )}
        {node.data.notes !== null && (
            <div className="col-3">
                <h3>{node.data.name}</h3>
                <p>Age {node.data.age}<br />
                <span className="smaller">({node.data.notes})</span></p>
            </div>
        )} */}
            <div className="col-3">
                <h3>{node.data.name}</h3>
            </div>
            <div className="col-3">
                <p>{node.data.notes}</p>
            </div>
            <div className="col-6">
                <ul>
                {node.data.competitions.map(competition => (
                    <li key={competition.id} className="mb-3">{competition.data.Title}<br /><span className="smaller"><strong>{competition.data.month} {competition.data.year}</strong> | <strong>Age: {competition.data.age}</strong></span></li>
                ))}
                </ul>
            </div>
            {/* <div className="col-3">
                <p>{node.data.month} {node.data.year}</p>
            </div> */}
        </div>
        <hr />
        </div>
        ))}
        </Fade>
        <div className="spacing-lg"></div>
    </div>
    </Layout>
)
export default Teaching;

export const query = graphql`
{
    photo:airtable(table: {eq: "Teaching"}, data: {item: {eq: "photo"}, section: {eq: "Philosophy"}, publish: {eq: true}}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    text:airtable(table: {eq: "Teaching"}, data: {item: {eq: "text"}, section: {eq: "Philosophy"}, publish: {eq: true}}) {
        data {
          text {
            childMarkdownRemark {
              html
            }
          }
          month
        year
        }
      }

      lishan:airtable(table: {eq: "Teaching"}, data: {item: {eq: "achievements"}, section: {eq: "Philosophy"}, publish: {eq: true}}) {
        data {
        competitions {
            data {
                Title
                month
                year
            }
            id
        }
        }
      }

    students:allAirtable(filter: {table: {eq: "Teaching"}, data: {section: {eq: "Students"}, publish: {eq: true}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
          data {
            alt
            url
            title
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                  html
                }
              }
          }
          recordId
        }
      }

      achievements:allAirtable(filter: {table: {eq: "Teaching"}, data: {section: {eq: "Achievements"}, publish: {eq: true}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
          data {
            name
            notes
            age
            month
            year
            competitions {
                data {
                    Title
                    month
                    year
                    age
                }
                id
            }
          }
          recordId
        }
      }
}  
`;